import { Anchor, Smubble, Input, ButtonSubmit } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import handleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import mixtapeNotFoundStyles from '~/styles/components/Mixtapes/MixtapeNotFound.css';

import type { ChangeEvent } from 'react';
import type { RouteTyping } from 'types';

export const links = () => {
  return [{ rel: 'stylesheet', href: mixtapeNotFoundStyles }];
};
const MixtapeNotFound = () => {
  const { t } = useTranslation();
  const matches = useMatches();
  const env = (matches.find((m) => m.handle?.id === handleIds.Root)?.data as RouteTyping<'Root'>)?.env;
  const [error, setError] = useState<string | boolean>('');
  const codeInput = useRef<HTMLInputElement>(null);
  const [codeNotExist, setCodeNotExist] = useState(false);
  const fetcher = useFetcher();

  const handleFlipCodeInput = (e: ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const inputValue = formData.get('join_code');
    // allow + as a first character, otherwise blocks special characters
    const joinCodeRegex = /^\+?([A-Za-z0-9]+)?$/;
    let errorMessage = '';
    if (typeof inputValue === 'string' && !joinCodeRegex.test(inputValue)) {
      // matches alphanumeric and + characters
      const alphaNumericPlusRegex = /[A-Za-z0-9+]/;
      // create a string of unique string for characters that do not match alphaNumericPlusRegex
      const invalidCharacters = [...new Set([...inputValue])].
      filter((char) => !alphaNumericPlusRegex.test(char)).
      join('');

      // if invalid characters exist, update the error message to display those invalid characters
      if (invalidCharacters.length > 0) {
        errorMessage = t('shared.joinCodeInvalidCharacters', { characters: invalidCharacters });
      }
      // if the user adds a + after the first character, add the error message for invalid + characters
      if (inputValue.lastIndexOf('+') > 0) {
        const shouldAppendPlusErrorAsNewSentence = errorMessage.length > 0;
        errorMessage = shouldAppendPlusErrorAsNewSentence ?
        t('shared.invalidCharactersAtTheStart', { characters: invalidCharacters }) :
        t('shared.plusOnlyValidAtStart');
      }
    }

    setError(errorMessage);
    setCodeNotExist(false);
  };

  useEffect(() => {
    if (fetcher.data?.status === 404 && fetcher.data.joinCode === codeInput.current?.value) {
      setCodeNotExist(true);
    }
  }, [fetcher.data]);

  return (
    <div className="mixtapeNotFound__wrapper">
      <Smubble height={62} />
      <div className="mixtapeNotFound__card">
        <div className="mixtapeNotFound__body">
          <h1 className="mt0 fk-fontSize__36">{t('mixtapeNotFound.cantFindMixtape')}</h1>
          <p className={classNames('mb2', { 'fk-error': codeNotExist })}>{t('mixtapeNotFound.askYourLead')}</p>
          <fetcher.Form onChange={handleFlipCodeInput} method="post" action={resourceRoutes.mixtapeJoinCode}>
            <Input
              label={t('common.joinCode')}
              ref={codeInput}
              name="join_code"
              maxLength={20}
              placeholder={t('common.enterAnotherJoinCode')}
              error={error}
              floatingLabel />

            <ButtonSubmit
              className="mt1"
              type="submit"
              variant="block"
              data-testid="mixtapeNotFound__button__submit"
              aria-label={t('mixtapeNotFound.submitCode')}
              size="58"
              loading={fetcher.state !== 'idle'}>

              {t('shared.tryAgain')}
            </ButtonSubmit>

            <div className="mixtapeNotFound__or">
              <span className="mixtapeNotFound__or-line" />
              <span className="mixtapeNotFound__or-text">{t('common.or')}</span>
            </div>
            <Anchor
              data-testid="mixtapeNotFound__anchor__admin"
              to={env.CLIENT_AUTH_SERVICE_URL}
              variant="block"
              size="58"
              theme="secondary">

              {t('common.signIn')}
            </Anchor>
          </fetcher.Form>
        </div>
      </div>
    </div>);

};

export default MixtapeNotFound;